import { render, staticRenderFns } from "./HeroSliderSlide.vue?vue&type=template&id=f8eb08a4&"
import script from "./HeroSliderSlide.vue?vue&type=script&lang=ts&"
export * from "./HeroSliderSlide.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ImageComponent: require('/srv/rir-platform/tourism/src/tourism-front/components/common/ImageComponent/index.vue').default,HeroSliderButton: require('/srv/rir-platform/tourism/src/tourism-front/components/common/HeroSlider/HeroSliderButton.vue').default})
