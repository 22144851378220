//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {mapGetters} from "vuex";

export default {
  name: 'HeroSlider',
  components: {
    HeroSliderSlide: () => import("@/components/common/HeroSlider/HeroSliderSlide"),
    Icon24FillArrowLeft: () => import('@/assets/icons/Icon24FillArrowLeft.svg?inline'),
    Icon24FillArrowRight: () => import('@/assets/icons/Icon24FillArrowRight.svg?inline'),
    RoundButton: () => import('@/components/common/RoundButton'),
  },
  data() {
    return {
      current: 0,
      timer: null,
      defaultTime: 7,
      currentTime: 7
    }
  },
  computed: {
    ...mapGetters({
      city: 'cities/default',
      pending: 'hero-slider/pending',
      slides: 'hero-slider/slides',
    }),
    navigation() {
      return this?.slides?.length > 1
    },
    progress() {
      return {
        width: `${100 / this.defaultTime * this.currentTime}%`
      }
    },
    style() {
      return {
        left: `${-100 * this.current}vw`
      }
    },
    iconSize() {
      return this.$mediaSwitch({defaultValue: '28px', sm: '16px', md: '18px'})
    },
    btnSize() {
      return this.$mediaSwitch({defaultValue: '48px', sm: '40px'})
    }
  },
  watch: {
    currentTime(n) {
      if (n === -1) {
        this.currentTime = this.defaultTime
        this.nextSlide()
      }
    }
  },
  mounted() {
    this.startTimer()
  },
  methods: {
    nextSlide() {
      this.stopTimer()
      if (this.current < this.slides.length - 1) {
        this.current += 1
      } else {
        this.current = 0
      }
      this.currentTime = this.defaultTime
      this.startTimer()
    },
    previewSlide() {
      this.stopTimer()
      if (this.current > 0) {
        this.current -= 1
      } else {
        this.current = this.slides.length - 1
      }
      this.currentTime = this.defaultTime
      this.startTimer()
    },
    setCurrentSlide(index) {
      this.stopTimer()
      this.current = index
      this.currentTime = this.defaultTime
      this.startTimer()
    },
    showButtons(slide) {
      return slide.buttons.length > 0
    },
    startTimer() {
      this.timer = setInterval(() => {
        this.currentTime--
      }, 1000)
    },
    stopTimer() {
      clearTimeout(this.timer)
    }
  }
}
