




























import Vue from 'vue';
import type {PropType} from 'vue';
import {Button} from "~/types/hero-slider/button";

export default Vue.extend({
  name: "HeroSliderSlide",
  components: {
    HeroSliderButton: () => import('@/components/common/HeroSlider/HeroSliderButton.vue'),
    ImageComponent: () => import('@/components/common/ImageComponent/index.vue'),
  },
  props: {
    title: {
      type: String,
      default: () => '',
    },
    description: {
      type: String,
      default: () => '',
    },
    image: {
      type: String,
      default: () => require('@/assets/images/default-image.svg')
    },
    buttons: {
      type: Array as PropType<Button[]>,
      default: () => [],
    }
  },
})
