//
//
//
//
//
//
//
//
//
//
//

import {mapGetters} from "vuex";

export default {
  name: "SectionHeroSlider",
  components: {
    Section: () => import("@/components/common/Section"),
    EmptyHeroSlider: () => import("@/components/common/EmptyHeroSlider"),
    HeroSlider: () => import("@/components/common/HeroSlider")
  },
  props: {
    disableMarginTop: {
      type: Boolean,
      default: () => false
    },
    disableMarginBottom: {
      type: Boolean,
      default: () => false
    }
  },
  computed: {
    ...mapGetters({
      city: 'cities/default',
      pending: 'hero-slider/pending',
      slides: 'hero-slider/slides',
    }),
    show() {
      return this.slides.length > 0 && !this.pending || this.pending
    }
  },
  mounted() {
    this.$store.dispatch('hero-slider/fetch', {
      params: {
        resolution: 'micro',
        lang: this.$i18n.locale,
        city_id: this.$config.singleCity ? this.city.id : null
      }
    })
  }
}
