


















import Vue from 'vue'

export default Vue.extend({
  name: "HeroSliderLink",
  props: {
    title: {
      type: String,
      default: () => ''
    },
    link: {
      type: String,
      default: () => ''
    },
    external: {
      type: Boolean,
      default: () => false
    },
  }
})
